import { Component, OnInit } from '@angular/core';
import { AppSettings } from './app.settings';
import { ThinService } from './layout/layouts/vertical/thin/thin.service';
import MqttURLConfig from '../assets/config/config.json';
import { SignInService } from './modules/auth/sign-in/sign-in.service';
import { mqttTopic, Settings } from './app.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
declare const mqtt;
// import * as mqtt from 'mqtt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   * Constructor
   */

  // mqttConnectConfig: IMqttServiceOptions;
  status: string;
  subscriptionLive;
  // mqttConfig: IMqttServiceOptions;
  devMqttURL = MqttURLConfig.dev;
  stagingMqttURL = MqttURLConfig.staging;
  prodMqttURL = MqttURLConfig.prod;
  mqttClient: any;
  companyId: string = localStorage.getItem('companyId');
  setup = false;
  settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public thinService: ThinService,
    private signinSrv: SignInService,
    private http: HttpClient
  ) {
    this.settings = this.appSettings.settings;
    // this.settings.loadingSpinner = false;
    this.getMenu();
    this.appSettings.loginFlag$.subscribe((data) => {
      if (data) {
        if (!this.mqttClient) {
          this.setupMqtt();
        } else {
          this.mqttClient.reconnect();
        }
      } else {
        if (this.mqttClient) {
          this.mqttClient.end();
        }
      }
    });
    console.log('APP start');
  }

  ngOnInit() {
    this.companyId = localStorage.getItem('companyId');
    if (this.signinSrv.accessToken && !this.mqttClient) {
      this.setupMqtt();
    }
  }

  setupMqtt() {
    this.signinSrv.getMqttInfo().subscribe((data) => {
      if (data['code'] === 200) {
        let mqttInfors = data['result'];
        mqttInfors = JSON.stringify(mqttInfors);
        localStorage.setItem('mqttInfoms', mqttInfors);
        const mqttInfos = JSON.parse(localStorage.getItem('mqttInfoms'));
        const username = mqttInfos['userName'];
        const password = mqttInfos['password'];
        const id = 'rmv2_' + Math.random().toString(16).substr(2, 8);
        const options = {
          username: username,
          password: password,
          clientId: id,
          protocolVersion: 5,
          protocol: environment.mqttProtocol,
          port: environment.mqttPort,
          host: window.location.hostname,
          hostname: window.location.hostname,
        };
        this.mqttClient = mqtt.connect(options);
        this.mqttClient.on('connect', () => {
          this.subscribeTopic();
        });
      }
    });
  }

  subscribeTopic() {
    this.companyId = localStorage.getItem('companyId');
    for (const topic in mqttTopic) {
      const subTopic = mqttTopic[topic] + this.companyId;
      this.mqttClient.subscribe(subTopic, (err) => {
        console.log('success subscribed  topic:' + subTopic);
      });
    }
    this.mqttClient.on('message', (subTopic, message, packet) => {
      try {
        const data = JSON.parse(message.toString());
        if (subTopic.includes('mission/status')) {
          this.appSettings.socketMissionStatus = data;
          console.log('subTopic', subTopic);
          // console.log('Base Mission status Received Message: ' + message.toString() + '. Topic: ' + subTopic)
        } else if (
          subTopic.includes('robot/mission') &&
          !subTopic.includes('robot/mission/status')
        ) {
          this.appSettings.socketMissionData = data;
          // console.log('Base Mission Received Message: ' + message.toString() + '. Topic: ' + subTopic)
        } else if (subTopic.includes('robot/status')) {
          this.appSettings.socketRobotData = data;
          // console.log(
          //   'Base Received Message: ' +
          //     message.toString() +
          //     '. Topic: ' +
          //     subTopic
          // );
        } else if (subTopic.includes('event')) {
          this.appSettings.socketEventData = data;
          // console.log(
          //   'Base Received Message: ' +
          //     message.toString() +
          //     '. Topic: ' +
          //     subTopic
          // );
        } else if (subTopic.includes('request')) {
          this.appSettings.socketVC = data;
          // console.log('Base Received Message: ' + message.toString() + '. Topic: ' + subTopic)
        } else if (subTopic.includes('robot/control/status')) {
          this.appSettings.socketControlStatus = data;
        } else if (subTopic.includes('robot/notify')) {
          this.appSettings.socketNotificationData = data;
        } else if (subTopic.includes('robot/call')) {
          this.appSettings.socketAnswerCall = data;
        } else if (subTopic.includes('robot/state')) {
          this.appSettings.socketRbtStateData = data;
        } else if (subTopic.includes('/rm/mission/')) {
          this.appSettings.socketUserMission = data;
        } else if (subTopic.includes('/lift/command/status/')) {
          this.appSettings.socketLiftCommandStatus = data;
        } else if (subTopic.includes('/rm/lift/command/')) {
          this.appSettings.socketLiftCommand = data;
        } else if (subTopic.includes('/lift/status/')) {
          this.appSettings.socketLiftStatus = data;
        } else if (subTopic.includes('/robot/paths')) {
          this.appSettings.socketPathPlanner = data;
        } else if (subTopic.includes('/robot/log')) {
          this.appSettings.socketRobotLog = data;
        } else if (subTopic.includes('/robot/sensor-actuator/status/')) {
          this.appSettings.socketRobotSensors = data;
        } else if (subTopic.includes('/rm/job/')) {
          this.appSettings.socketJobData = data;
        } else if (subTopic.includes('/rm/event/')) {
          this.appSettings.socketEvent = data;
        } else if (subTopic.includes('/door/status/')) {
          this.appSettings.socketDoorStatus = data;
        } else if (subTopic.includes('/rm/va-zone-item/status/')) {
          this.appSettings.socketVaZoneItems = data;
        }
      } catch {
        console.log('Unexpect message: ' + message.toString());
      }
    });
  }

  getMenu() {
    const data = 0;
    this.thinService.getAccessRight(data).subscribe((data) => {
      this.appSettings.menuItem = data;
    });
  }

  getUserAccessRight(accessData) {
    this.appSettings.accessRight = accessData;
  }
}
