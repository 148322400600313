import { FormControl } from '@angular/forms';

export const specialCharsForbiddenInput = (
  formControl: FormControl
): Record<string, unknown> =>
  isForbidden(formControl.value)
    ? null
    : {
        specialCharsForbiddenInput: {
          valid: false,
        },
      };
export const forbiddenReg =
  /^(?!\s)(?!.*\s$)[^@#%&*()_|$:;+`~!^={}[\]\\"'<>,./?]+$/;

export const isForbidden = (val: string): boolean => forbiddenReg.test(val);

export const specialCharsForbiddenInputWithoutUnderscore = (
  formControl: FormControl
): Record<string, unknown> =>
  isForbiddenWithoutUnderscore(formControl.value)
    ? null
    : {
        specialCharsForbiddenInputWithoutUnderscore: {
          valid: false,
        },
      };

export const isForbiddenWithoutUnderscore = (val: string): boolean =>
  /^(?!\s)(?!.*\s$)[^@#%&*()|$:;+`~!^={}[\]\\"'<>,./?]+$/.test(val);

export const isNumberOnly = (val: string): boolean => /^\d+(\.\d+)*$/.test(val);

export const urlValidator = (
  formControl: FormControl
): Record<string, unknown> =>
  isValidUrl(formControl.value)
    ? null
    : {
        urlValidator: {
          valid: false,
        },
      };

export const isValidUrl = (val: string): boolean =>
  /^(https?:\/\/)?((([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,})|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(:(\d{1,5}))?(\/[a-zA-Z0-9_.-]+)*(\/?(\?([a-zA-Z0-9_-]+=[a-zA-Z0-9_%-]*)(&[a-zA-Z0-9_-]+=[a-zAZ0-9_%-]*)*)?(#([a-zA-Z0-9_-]+))?)?$/.test(
    val
  );

export const jsonValidator = (
  formControl: FormControl
): Record<string, unknown> | null => {
  const value = formControl.value;

  if (value === '') {
    return null; // Empty input is okay, no validation needed
  }
  if (parseInt(value)) {
    return {
      jsonValidator: {
        valid: false,
      },
    };
  }
  try {
    JSON.parse(value);
  } catch (e) {
    return {
      jsonValidator: {
        valid: false,
      },
    };
  }

  return null;
};

/**
 * Used to check the input value if contain special characters and check if it is an object.
 * When use it to check value form autocomplete component,
 * it will give response invalid if the value of the autocomplete is an object.
 * So need to check, if the value is an object.
 */
export const forbiddenInputWithObjectCheck = (
  formControl: FormControl
): Record<string, unknown> =>
  isForbidden(formControl.value) ||
  (typeof formControl.value === 'object' && !Array.isArray(formControl.value))
    ? null
    : {
        specialCharsForbiddenInput: {
          valid: false,
        },
      };
